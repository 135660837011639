import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { checkAuth } from '../apis/auth'
import { localStorageWrapper } from '../helpers/localStorage'
import Amplitude from '../utils/analytics/amplitude'
import { DisplayItemKey } from '../types/models/Presc'

type User = {
  id: string
  pharmacy_name: string
  plan: string
  qr_size: string
  edit_mode: EditMode
  display_items: DisplayItemDict
  app_type: string
  is_annotator: boolean
  is_activated_photo: boolean
  userId: string
  is_image_rotatable: boolean
  editable_item_font_size: string
  show_save_csv_button: boolean
}

type DisplayItemDict = {
  essential: DisplayItemKey[]
  sub: DisplayItemKey[]
}

type EditMode = {
  kana: boolean
  insurance_num: boolean
  birthday: boolean
  allowed_insurance_num_blank: boolean
}

type ResponseData = {
  version: null
  user: User
}

export const useRedirectToLogin = (): void => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    checkIsLoggedIn()
  }, [])

  const checkIsLoggedIn = async () => {
    try {
      const response = await checkAuth()
      console.log(response)
      onSuccess(response.data)
      Amplitude.setUserProperties({
        userId: response.data.user.id,
        pharmacyName: response.data.user.pharmacy_name,
      })
    } catch (error) {
      onFail()
      console.error(error)
    }
  }

  const onSuccess = (responseData: ResponseData) => {
    dispatchIsLoggedIn(responseData.user.app_type)
    localStorageWrapper.setItem('qr_size', responseData.user.qr_size)
    localStorageWrapper.setItem('userId', responseData.user.id)
    localStorageWrapper.setItem(
      'essentialDisplayItems',
      responseData.user.display_items.essential
    )
    localStorageWrapper.setItem(
      'subDisplayItems',
      responseData.user.display_items.sub
    )
    localStorageWrapper.setItem(
      'isPatientKanaEditable',
      responseData.user.edit_mode.kana
    )
    localStorageWrapper.setItem(
      'isInsuranceNumEditable',
      responseData.user.edit_mode.insurance_num
    )
    localStorageWrapper.setItem(
      'isBirthdayEditable',
      responseData.user.edit_mode.birthday
    )
    localStorageWrapper.setItem(
      'editableItemFontSize',
      responseData.user.editable_item_font_size
    )
    localStorageWrapper.setItem(
      'allowedInsuranceNumBlank',
      responseData.user.edit_mode.allowed_insurance_num_blank
    )
    localStorageWrapper.setItem(
      'isImageRotatable',
      responseData.user.is_image_rotatable
    )
    localStorageWrapper.setItem('app_type', responseData.user.app_type)
    localStorageWrapper.setItem('is_annotator', responseData.user.is_annotator)
    localStorageWrapper.setItem(
      'is_activated_photo',
      responseData.user.is_activated_photo
    )
    localStorageWrapper.setItem(
      'show_save_csv_button',
      responseData.user.show_save_csv_button
    )
  }

  const onFail = () => {
    navigateToLogin()
  }

  const dispatchIsLoggedIn = (appType: string) => {
    dispatch({ type: 'setIsLoggedIn', isLoggedIn: true, appType: appType })
  }

  const navigateToLogin = () => {
    history.push('/login')
  }
}
