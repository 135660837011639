import {
  Snackbar,
  Stack,
  IconButton,
  Modal,
  Box,
  Backdrop,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import { useCallback, useState } from 'react'
import {
  postBasicResultReport,
  postMedResultReport,
  postMedResultShortage,
} from '../apis/reportPresc'
import { BasicResult, Prescription } from '../types/models/Presc'
import { useLoading } from './useLoading'
import { CustomButton } from '../components/molecules/CustomButton'

type Params = {
  prescription: Prescription
  onSuccess: () => Promise<void>
}

export const useReportPresc = ({ prescription, onSuccess }: Params) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isSnackAlertOpen, setIsSnackAlertOpen] = useState<boolean>(false)
  const [isSending, setIsSending] = useState(false)
  const theme = useTheme()

  const ReportSendingOverlay = isSending && (
    <Stack
      sx={{
        zIndex: 1305,
        position: 'absolute',
        bgcolor: 'rgb(0,0,0, 0.4)',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      spacing={2}
    >
      <CircularProgress style={{ width: '100px', height: '100px' }} />
      <Typography sx={{ color: '#fff', fontSize: '24px' }}>
        報告送信中...
      </Typography>
    </Stack>
  )
  const errorTypes = [
    { type: 'med_shortage', displayLabel: '医薬品の漏れ' },
    {
      type: 'multiple_medical_speciality',
      displayLabel: '診療科ごとにQRを分けてほしい',
    },
    {
      type: 'doctor_name',
      displayLabel: '新字/旧字の誤り',
    },
    {
      type: 'adjust_usage_comment',
      displayLabel: '用法とコメントの分け方',
    },
    { type: 'other_reason', displayLabel: 'その他' },
  ]

  const renderSnackBar = () => (
    <div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <MuiAlert
          elevation={10}
          variant="filled"
          iconMapping={{
            success: (
              <CampaignRoundedIcon style={{ fontSize: 40, color: 'black' }} />
            ),
          }}
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '24px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: 600,
          }}
          style={{
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={() => setIsModalOpen(false)}
            style={{ position: 'absolute', top: '0', right: '0' }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Stack direction="column" alignItems="center">
            <Typography variant="h5" sx={{ pb: 2 }}>
              その他AIへの報告
            </Typography>
            <Stack>
              {errorTypes.map((item) => (
                <Stack key={item.type} p={1}>
                  <CustomButton
                    variant="contained"
                    sx={{ width: 400, height: 50 }}
                    onClick={() => reportError(item.type)}
                  >
                    {item.displayLabel}
                  </CustomButton>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </MuiAlert>
      </Modal>
      <Snackbar
        style={{
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        open={isSnackAlertOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackAlertOpen(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          iconMapping={{
            success: <CheckCircleIcon style={{ fontSize: 20 }} />,
          }}
          sx={{
            fontSize: '15px',
            padding: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: 300,
            backgroundColor: theme.palette.success.main,
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={() => setIsSnackAlertOpen(false)}
            style={{ position: 'absolute', top: '0', right: '0' }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
          AIへの報告が完了しました。
        </MuiAlert>
      </Snackbar>
    </div>
  )

  const reportBasicResult = useCallback(
    async (key: keyof BasicResult, isReported: boolean) => {
      try {
        setIsSending(true)
        await postBasicResultReport({
          prescId: prescription.id,
          key,
          isReported,
        })
        await onSuccess()
        setIsSending(false)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, onSuccess, postBasicResultReport]
  )

  const reportMedResult = useCallback(
    async (rpNum: number, isReported: boolean) => {
      try {
        setIsSending(true)
        await postMedResultReport({
          prescId: prescription.id,
          rpNum,
          isReported,
        })
        await onSuccess()
        setIsSending(false)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, onSuccess, postMedResultReport]
  )

  const reportError = useCallback(
    async (ocrErrorLabel: string) => {
      try {
        setIsSending(true)
        await postMedResultShortage({
          prescId: prescription.id,
          ocrErrorLabel,
        })
        await onSuccess()
        setIsSending(false)
        setIsModalOpen(false)
        setIsSnackAlertOpen(true)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, postMedResultShortage]
  )

  const openModal = useCallback(() => {
    onSuccess()
    setIsSending(false)
    setIsModalOpen(true)
  }, [onSuccess, setIsModalOpen])
  return {
    reportBasicResult,
    reportMedResult,
    openModal,
    renderSnackBar,
    ReportSendingOverlay,
  }
}
