import { useState } from 'react'
import { localStorageWrapper } from '../helpers/localStorage'
import { CustomFab } from '../components/molecules/CustomFab'
import { generateCsv } from '../apis/prescription'
import { Button, IconButton, Snackbar, useTheme } from '@mui/material'
import { SendToMobile } from '@mui/icons-material'
import MuiAlert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export const useGenerateCsvButton = (selectedPrescId: string | null) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(true)
  const theme = useTheme()

  const Toast = (
    <Snackbar
      style={{
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      open={isModalOpen}
      autoHideDuration={3000}
      onClose={() => setIsModalOpen(false)}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity="success"
        iconMapping={{
          success: <CheckCircleIcon style={{ fontSize: 20 }} />,
        }}
        sx={{
          fontSize: '15px',
          padding: '4px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: 300,
          backgroundColor: theme.palette.success.main,
        }}
      >
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={() => setIsModalOpen(false)}
          style={{ position: 'absolute', top: '0', right: '0' }}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
        送信しました。
      </MuiAlert>
    </Snackbar>
  )

  const Button_ = selectedPrescId && (
    <div>
      {localStorageWrapper.getItem('show_save_csv_button') && (
        <Button
          variant="outlined"
          color="success"
          sx={{
            marginTop: 1,
            float: 'right',
          }}
          onClick={() => {
            setIsButtonEnabled(false)
            generateCsv(selectedPrescId)
              .then(() => {
                setIsModalOpen(true)
              })
              .finally(() => {
                setIsButtonEnabled(true)
              })
          }}
          disabled={!isButtonEnabled}
        >
          レセコンに送信
          <SendToMobile />
        </Button>
      )}
      {Toast}
    </div>
  )

  return Button_
}
