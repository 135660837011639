import React, { FC } from 'react'

import {
  Stack,
  Box,
  Modal,
  Typography,
  Checkbox,
  FormControlLabel,
  useTheme,
} from '@mui/material'
import GppGoodRoundedIcon from '@mui/icons-material/GppGoodRounded'
import { CloseIcon } from '../../icons/CloseIcon'
import {
  LowAccuracyAlert,
  GigiAlert,
  ReshapeReport,
} from '../../types/models/Presc'
import {
  GigiAlertTypography,
  ReshapeReportTypography,
  AccuracyAlertTypography,
  MedChangeTypography,
} from '../molecules/HighlightedTypography'
import { MedChangeAlert } from '../../types/models/MedChangeLog'
import { CustomButton } from '../molecules/CustomButton'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  doctor_name: string | undefined
  hospital_name: string | undefined
  low_accuracy_alerts: LowAccuracyAlert[] | undefined
  reshape_reports: ReshapeReport[] | undefined
  gigi_alerts: GigiAlert[] | undefined
  med_change_alerts: MedChangeAlert[] | undefined
}

export const MedicalConfirmationModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  doctor_name,
  hospital_name,
  reshape_reports,
  low_accuracy_alerts,
  gigi_alerts,
  med_change_alerts,
}) => {
  const needCheck = gigi_alerts?.length !== 0 || med_change_alerts?.length !== 0
  const theme = useTheme()
  return (
    <Modal open={isOpen} style={{ margin: '48px' }}>
      <Stack
        sx={{
          [theme.breakpoints.down('md')]: {
            width: '100%',
            transform: 'translate(-50%,-50%)',
            top: '50%',
          },
          marginLeft: '16px',
          marginRight: '16px',
          alignContent: 'flex-start',
        }}
        position="absolute"
        direction="column"
        top="0%"
        left="50%"
        overflow="scroll"
        width="50%"
        height="500px"
        borderRadius="8px"
        p={1}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 1)',
        }}
      >
        <Stack sx={{ height: '50px' }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              p: 3,
              marginBottom: '8px',
            }}
          >
            {'✔︎AIチェック結果'}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          overflow="scroll"
          sx={{ width: '100%', height: '450px', alignContent: 'flex-start' }}
        >
          <Box
            sx={{
              marginTop: '8px',
              marginBottom: '0px',
              marginLeft: '24px',
              marginRight: '16px',
              alignContent: 'flex-start',
            }}
          >
            <>
              {needCheck && (
                <Box
                  sx={{
                    marginTop: '0px',
                    alignContent: 'flex-start',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontSize: 16,
                    }}
                  >
                    {'- 要確認'}
                  </Typography>
                </Box>
              )}
              {!!gigi_alerts?.length &&
                gigi_alerts.map((item) => (
                  <Stack key={item.id}>
                    <GigiAlertTypography
                      text={item.alert_text}
                      medNames={item.medicine_names}
                    />
                  </Stack>
                ))}
              {!!med_change_alerts?.length &&
                med_change_alerts.map((item) => (
                  <Stack key={item.id}>
                    <MedChangeTypography
                      text={item.text}
                      medName={item.original_med_name}
                    />
                  </Stack>
                ))}
            </>
          </Box>
          <Box
            sx={{
              marginTop: '8px',
              marginBottom: '0px',
              marginLeft: '24px',
              marginRight: '16px',
            }}
          >
            {!!reshape_reports?.length && (
              <>
                <Box sx={{ marginTop: '0px' }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontSize: 16,
                    }}
                  >
                    {'- 実施報告'}
                  </Typography>
                </Box>
                {reshape_reports.map((item) => (
                  <Stack key={item.id}>
                    <ReshapeReportTypography
                      text={item.report_text}
                      highlightWords={item.targets}
                    />
                  </Stack>
                ))}
              </>
            )}
          </Box>
          <Box
            sx={{
              marginTop: '8px',
              marginBottom: '0px',
              marginLeft: '24px',
              marginRight: '16px',
            }}
          >
            {!!low_accuracy_alerts?.length && (
              <>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontSize: 16,
                    }}
                  >
                    {'- 読み取り精度セーフティネット'}
                  </Typography>
                </Box>
                {low_accuracy_alerts.map((item) => (
                  <Stack key={item.id}>
                    <AccuracyAlertTypography
                      text={item.alert_text}
                      highlightWord={item.target}
                    />
                  </Stack>
                ))}
              </>
            )}
          </Box>
          <Box sx={{ width: '100%' }} justifyContent="center">
            {!low_accuracy_alerts?.length &&
              !reshape_reports?.length &&
              !gigi_alerts?.length &&
              !med_change_alerts?.length && (
                <>
                  <Box
                    sx={{ width: '100%' }}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                  >
                    <GppGoodRoundedIcon style={{ fontSize: 120 }} />
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontSize: 16,
                        paddingTop: '32px',
                        paddingBottom: '40px',
                      }}
                    >
                      {'AIチェックの結果、処方箋に問題はありませんでした。'}
                    </Typography>
                  </Box>
                </>
              )}
          </Box>
        </Stack>
        <Box
          display="flex"
          justifyContent="center"
          p={3}
          sx={{ height: '10%' }}
        >
          <CustomButton
            onClick={() => setIsOpen(false)}
            variant="contained"
            size="large"
            style={{ width: '110px' }}
          >
            確認完了
          </CustomButton>
        </Box>
      </Stack>
    </Modal>
  )
}
