import { Card, Stack, Grid, Typography } from '@mui/material'
import { FC } from 'react'

type PanelGroupProps = {
  title: string
  patient_count: number
  new_patient_rate: number
  patient_count_with_multiple_department: number
  event_count_with_many_meds: number
  rp_count: number
  rp_count_with_long_comment: number
  med_count: number
  unit_converted_med_count: number
}

type PanelProps = {
  main_label: string
  sub_label: string
  count: number
  unit: string
  denominator: string
}

export const DashboardVennPanelGroup: FC<PanelGroupProps> = ({
  title,
  patient_count,
  new_patient_rate,
  patient_count_with_multiple_department,
  event_count_with_many_meds,
  rp_count,
  rp_count_with_long_comment,
  med_count,
  unit_converted_med_count,
}) => {
  return (
    <Stack
      direction="column"
      sx={{
        height: '100%',
      }}
    >
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DashboardVennPanel
            main_label="新患"
            sub_label=""
            count={Math.floor(patient_count * new_patient_rate)}
            unit="名"
            denominator={`推定新患率${new_patient_rate * 100}%`}
          />
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DashboardVennPanel
            main_label="多科受付"
            sub_label=""
            count={patient_count_with_multiple_department}
            unit="名"
            denominator={`全${patient_count}名中`}
          />
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DashboardVennPanel
            main_label="多薬剤処方"
            sub_label="(薬剤4個以上)"
            count={event_count_with_many_meds}
            unit="名"
            denominator={`全${patient_count}名中`}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2} />
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DashboardVennPanel
            main_label="長文コメント"
            sub_label="(10文字以上)"
            count={rp_count_with_long_comment}
            unit="調剤"
            denominator={`全${rp_count}調剤中`}
          />
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DashboardVennPanel
            main_label="分包/力価単位変換"
            sub_label=""
            count={unit_converted_med_count}
            unit="薬剤"
            denominator={`全${med_count}薬剤中`}
          />
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Stack>
  )
}

const DashboardVennPanel: FC<PanelProps> = ({
  main_label,
  sub_label,
  count,
  unit,
  denominator,
}) => {
  const styles = {
    circle: {
      display: 'flex',
      justifyContent: 'center' as const,
      alignItems: 'center' as const,
      flexDirection: 'column' as const,
      width: '184px',
      height: '184px',
      border: '0.5px solid gray',
      borderRadius: '50%',
      backgroundColor: 'white',
    },
    label: {
      fontSize: '16px',
      fontWeight: 'normal' as const,
      height: '20px',
    },
    count: {
      fontSize: '48px',
      fontWeight: 'normal' as const,
      margin: '0px',
      padding: '5px',
    },
    unit: {
      fontSize: '32px',
      fontWeight: 'normal' as const,
      margin: '0px',
      padding: '0px',
    },
    denominator: {
      fontSize: '16px',
    },
  }

  return (
    <div style={styles.circle}>
      <div style={styles.label}>{main_label}</div>
      <div style={styles.label}>{sub_label || ' '}</div>
      <p style={styles.count}>
        <span>{count}</span>
        <span style={styles.unit}>{unit}</span>
      </p>
      <div style={styles.denominator}>({denominator})</div>
    </div>
  )
}
